<template>
  <v-container fluid>
    <v-layout column>
      <v-row justify="center" class="pa-6">
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <v-card class="text-center pa-2" outlined>
            <v-card-text>
              <v-flex class="mb-4">
                <v-avatar size="120" class="mr-4">
                  <img src="../../assets/user.svg" alt="Avatar" />
                </v-avatar>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="pb-2"
                      ><h3>{{ this.getUserInfo().fullName }}</h3>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ this.getUserInfo().userName }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="my-2"
                block
                color="secondary"
                :loading="loading"
                @click.native="dialog = true"
              >
                {{ $t("ChangePassword") }}
                <v-icon left dark>mdi-lock-question</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>

        <v-col cols="12" lg="6" md="6" sm="12" xs="12" v-if="isInRole(0)">
          <v-card class="text-center" outlined>
            <v-card-text>
              <v-text-field
                outlined
                filled
                readonly
                hide-details
                dense
                class="pb-3"
                :label="$t('University')"
                v-model="this.$store.state.userData.universityName"
              ></v-text-field>
              <v-text-field
                outlined
                filled
                readonly
                hide-details
                dense
                class="pb-3"
                :label="$t('College')"
                v-model="this.$store.state.userData.collegeName"
              ></v-text-field>
              <v-text-field
                outlined
                filled
                readonly
                hide-details
                dense
                class="pb-3"
                :label="$t('Department')"
                v-model="this.$store.state.userData.departmentName"
              ></v-text-field>
              <v-text-field
                outlined
                filled
                readonly
                hide-details
                dense
                class="pb-3"
                :label="$t('Stage')"
                :value="
                  this.$store.state.userData.stageName +
                    ' / ' +
                    this.$store.state.userData.studyType
                "
              ></v-text-field>
              <v-text-field
                outlined
                filled
                readonly
                hide-details
                dense
                :label="$t('Section')"
                v-model="this.$store.state.userData.sectionName"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="showEditBtn"
                block
                color="secondary"
                :loading="loading"
                @click.native="studentBasicInfoDialog = true"
              >
                {{ $t("profile.EditBasicInfo") }}
                <v-icon left dark>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" lg="3" md="6" sm="12" xs="12" v-if="isInRole(0)">
          <v-hover v-slot="{ hover }">
          <v-card flat class="d-flex flex-column cardTransition">
            <v-avatar size="200" class="ma-auto">
              <v-icon :color="hover ? 'primary' : ''" style="font-size: 200px;"> mdi-facebook-workplace </v-icon>
            </v-avatar>
            <v-btn :color="hover ? 'primary' : 'secondary'"  class="mx-auto my-3" :to="{ name: 'portfolio.WorkSample.title' }">{{
              $t("portfolio.WorkSample.title")
            }}</v-btn>
          </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" xs="12" v-if="isInRole(0)">
          <v-hover v-slot="{ hover }">
          <v-card
            flat
            class="d-flex flex-column cardTransition"
          >
            <v-avatar size="200" class="ma-auto">
              <v-icon :color="hover ? 'success' : ''" style="font-size: 200px;"> mdi-chart-bar </v-icon>
            </v-avatar>
            <v-btn :color="hover ? 'success' : 'secondary'" class="mx-auto my-3" :to="{ name: 'portfolio.Achievements.title' }">{{
              $t("portfolio.Achievements.title")
            }}</v-btn>
          </v-card>
          </v-hover>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="12" xs="12" v-if="isInRole(0)">
          <v-hover v-slot="{ hover }">
            <v-card flat class="d-flex flex-column cardTransition">
              <v-avatar size="200" class="ma-auto">
                <v-icon :color="hover ? 'yellow darken-1' : ''" style="font-size: 200px;">
                  mdi-chart-timeline-variant-shimmer
                </v-icon>
              </v-avatar>
              <v-btn :color="hover ? 'yellow darken-1' : 'secondary'" class="mx-auto my-3" :to="{ name: 'portfolio.Goals.title' }">
                {{ $t("portfolio.Goals.title") }}</v-btn
              >
            </v-card>
          </v-hover>
        </v-col> -->

        <v-col cols="12" lg="6" md="6" sm="12" xs="12" v-if="isInRole(2)">
          <v-card outlined class="text-center">
            <v-card-text class="text-center pa-0 ma-0 mt-3 mb-3">
              <v-avatar size="64" class="mr-4">
                <v-img :src="teacherInfo.teacherPhotoUrl" lazy-src="../../assets/user.svg"> </v-img>
              </v-avatar>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-text>
              <v-list two-line>
                <v-row>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-phone </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ teacherInfo.phoneNum }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $t("MobileNumber") }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-email </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>{{ teacherInfo.email }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $t("Email") }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-map-marker </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title
                          >{{ teacherInfo.countryText }},
                          {{ teacherInfo.cityText }}</v-list-item-title
                        >
                        <v-list-item-subtitle>{{ $t("Address") }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>
                  </v-col>
                  <v-col cols="12" lg="6" md="6" sm="12" xs="12">
                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-school</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ teacherInfo.certificate }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ $t("Certificate") }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-certificate-outline </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ teacherInfo.scientificDegreesText }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ $t("ScientificDegree") }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>

                    <v-list-item>
                      <v-list-item-icon>
                        <v-icon color="indigo"> mdi-auto-fix </v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ teacherInfo.specialization }}
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ $t("Specialization") }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider inset></v-divider>
                  </v-col>
                </v-row>

                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-human-male-board </v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-chip-group active-class="primary--text" column>
                      <v-chip v-for="tag in teacherLectures" :key="tag.subjectGuid">
                        {{ tag.subjectName }}
                      </v-chip>
                    </v-chip-group>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" class="px-6">
        <v-col cols="12">
          <v-card outlined v-if="isInRole(0) && studentPayments && studentPayments.length > 0">
            <v-card-title>{{ $t("profile.Installments") }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-data-table
                :items="studentPayments"
                :hide-default-footer="true"
                :items-per-page="100"
                :item-key="stageLevel"
                :headers="HeaderColumns"
              >
                <template slot="body.append">
                  <tr
                    class="primary--text text--darken-1"
                    :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-table-row' : ''"
                  >
                    <td class="font-weight-bold">
                      {{ $t("profile.Total") }}
                    </td>
                    <td
                      class="font-weight-bold"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''"
                    >
                      <span class="hidden-sm-and-up">
                        {{ $t("profile.currentStagePlanAmount") }}
                      </span>

                      {{ studentPaymentsTotal.currentStagePlanAmount }}
                    </td>
                    <td
                      class="font-weight-bold"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''"
                    >
                      <span class="hidden-sm-and-up">
                        {{ $t("profile.paymentAmount") }}
                      </span>

                      {{ studentPaymentsTotal.paymentAmount }}
                    </td>
                    <td
                      class="font-weight-bold"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''"
                    >
                      <span class="hidden-sm-and-up">
                        {{ $t("profile.discountAmount") }}
                      </span>
                      {{ studentPaymentsTotal.discountAmount }}
                    </td>
                    <td
                      class="font-weight-bold"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''"
                    >
                      <span class="hidden-sm-and-up">
                        {{ $t("profile.refundAmount") }}
                      </span>
                      {{ studentPaymentsTotal.refundAmount }}
                    </td>
                    <td
                      class="font-weight-bold"
                      :class="$vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''"
                    >
                      <span class="hidden-sm-and-up">
                        {{ $t("profile.remainingAmount") }}
                      </span>
                      {{ studentPaymentsTotal.remainingAmount }}
                    </td>
                  </tr>

                  <!-- <tfoot
          v-if="
            footerItems && footerItems.filter((f) => f.showSummary).length > 0
          "
        >
          <tr
            class="primary--text "
            :class="
              $vuetify.breakpoint.xsOnly
                ? ' v-data-table__mobile-table-row'
                : ''
            "
          >
            <template v-for="(item, i) in footerItems">
              <td v-if="i == 0" :key="i" class="d-none d-sm-flex pa-3">
                {{ $t("total") }}
              </td>
              <td
                v-else-if="!item.showSummary"
                :key="i"
                :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"
              ></td>
              <td
                v-else
                :key="i"
                :class="
                  $vuetify.breakpoint.xsOnly ? 'v-data-table__mobile-row' : ''
                "
              >
                <span class="hidden-sm-and-up">
                  {{ $t("total") + " " + item.text }}
                </span>
                {{ sumTotals(item.value) | number("0,0") }}
              </td>
            </template>
          </tr>
        </tfoot> -->
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-toolbar dark color="primary darken-1 mb-5">
            <v-toolbar-title>
              <h3>{{ $t("ChangePassword") }}</h3>
            </v-toolbar-title>
            <v-spacer />
            <v-btn @click="dialog = !dialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="py-0" cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="passwordOld"
                    :label="$t('OldPassword')"
                    outlined
                    :rules="[rules.required]"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                  <v-text-field
                    v-model="passwordNew"
                    :label="$t('NewPassword')"
                    outlined
                    :rules="[rules.required]"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show2 = !show2"
                  ></v-text-field>
                  <v-text-field
                    v-model="confirmPassword"
                    :label="$t('ConfirmTheNewPassword')"
                    outlined
                    :rules="[rules.required, rules.confirmPassword]"
                    :type="show3 ? 'text' : 'password'"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="show3 = !show3"
                  ></v-text-field>
                  <v-checkbox
                    v-model="logOutFromAllDevices"
                    :label="$t('LogOutFromAllDevices')"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">{{ $t("cancel") }}</v-btn>
            <v-btn
              :loading="loadSave"
              color="blue darken-1"
              :disabled="checkEditorsVaildation"
              text
              @click="saveChangePassword"
              >{{ $t("save") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="studentBasicInfoDialog" max-width="600px" v-if="isInRole(0)">
        <v-card>
          <v-toolbar dark color="primary darken-1 mb-5">
            <v-toolbar-title>
              <h3>{{ $t("profile.EditBasicInfo") }}</h3>
            </v-toolbar-title>
            <v-spacer />
            <v-btn @click="studentBasicInfoDialog = !studentBasicInfoDialog" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="py-0" cols="12" sm="12" md="12">
                  <v-row>
                    <v-col cols="12" sm="12" md="4">
                      <v-img
                        max-width="100"
                        contain
                        max-height="100"
                        :src="studentImageUrl"
                        lazy-src="../../assets/user.svg"
                      ></v-img>
                    </v-col>
                    <v-col cols="12" sm="12" md="8">
                      <v-file-input
                        :rules="[
                          value =>
                            !value ||
                            value.size < maxUploadImageFileSize ||
                            `${$t('profile.BrowsePictures')} ${maxUploadImageFileSize /
                              1024 /
                              1024}MB`
                        ]"
                        prepend-icon="mdi-camera"
                        accept="image/png, image/jpeg, image/jpg, image/bmp, image/gif"
                        @change="imageChange"
                        v-model="studentImage"
                        :label="$t('profile.BrowsePictures')"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                      >
                      </v-file-input>
                      <ul style="color: #e53935">
                        <li style="font-weight: bold">
                          {{ $t("profile.OfficialPhoto") }}
                        </li>
                        <li style="font-weight: bold">
                          {{ $t("profile.AllowedModifyOnlyTime") }}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        :label="$t('MobileNumber')"
                        v-model="mobileNow"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        :label="$t('Email')"
                        :rules="[rules.email]"
                        v-model="email"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-select
                        v-model="studentGenderSelected"
                        :items="studentGender"
                        :rules="[rules.requiredGender]"
                        :label="$t('abs.Gender')"
                        item-text="DisplayText"
                        item-value="GenderType"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <v-dialog
                        ref="menuDate"
                        v-model="menuDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="studentDob"
                            :label="$t('profile.Birthday')"
                            :rules="[rules.required]"
                            persistent-hint
                            readonly
                            :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="studentDob"
                          :active-picker.sync="activePicker"
                          :rules="[rules.required]"
                          :max="
                            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                              .toISOString()
                              .substr(0, 10)
                          "
                          min="1940-01-01"
                          @input="menuDate = false"
                          :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                        ></v-date-picker>
                      </v-dialog>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <v-text-field
                        :label="$t('MotherName')"
                        v-model="motherName"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <v-select
                        v-model="bloodTypeSelected"
                        :items="bloodType"
                        :rules="[rules.required]"
                        :label="$t('BloodGroup')"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" md="6" sm="12">
                      <v-select
                        v-model="countryNowGuid"
                        :items="countries"
                        :rules="[rules.required]"
                        :label="$t('profile.CountryNow')"
                        item-text="country"
                        item-value="countryGuid"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                        @change="getCities('now')"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-select
                        v-model="cityNowGuid"
                        :items="citiesNow"
                        :rules="[rules.required]"
                        :label="$t('profile.CityNow')"
                        item-text="city"
                        item-value="cityGuid"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-select
                        v-model="countryPermanentGuid"
                        :items="countries"
                        :rules="[rules.required]"
                        :label="$t('profile.CountryPermanent')"
                        item-text="country"
                        item-value="countryGuid"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                        @change="getCities('permanent')"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-select
                        v-model="cityPermanentGuid"
                        :items="citiesPermanent"
                        :rules="[rules.required]"
                        :label="$t('profile.CityPermanent')"
                        item-text="city"
                        item-value="cityGuid"
                        :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="studentBasicInfoDialog = false">{{
              $t("cancel")
            }}</v-btn>
            <v-btn
              :loading="loadSave"
              color="blue darken-1"
              text
              @click="saveBasicInfo"
              :disabled="!studentBasicInfo || studentBasicInfo.isModifiedByStudent"
              >{{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  pageTitle: "My Profile",
  data() {
    return {
      HeaderColumns: [
        {
          text: this.$t("profile.stageName"),
          value: "stageName",
          show: true
        },
        {
          text: this.$t("profile.currentStagePlanAmount"),
          value: "currentStagePlanAmountFormat",
          show: true
        },
        {
          text: this.$t("profile.paymentAmount"),
          value: "paymentAmountFormat",
          show: true
        },
        {
          text: this.$t("profile.discountAmount"),
          value: "discountAmountFormat",
          show: true
        },
        {
          text: this.$t("profile.refundAmount"),
          value: "refundAmountFormat",
          show: true
        },
        {
          text: this.$t("profile.remainingAmount"),
          value: "remainingAmountFormat",
          show: true
        }
      ],
      activePicker: null,
      menuDate: false,
      show1: false,
      show2: false,
      show3: false,
      studentBasicInfo: {},
      rules: {
        required: value => !!value || this.$t("exam.required"),
        requiredGender: value => value !== null || this.$t("exam.required"),
        min: v => v.length >= 6 || this.$t("PasswordConsistsOf6OrMoreElements"),
        confirmPassword: () =>
          this.confirmPassword === this.passwordNew || this.$t("PasswordDoesNotMatch"),
        email: v =>
          !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("emailMustBeValid")
      },
      passwordNew: "",
      confirmPassword: "",
      passwordOld: "",
      logOutFromAllDevices: false,
      dialog: false,
      studentBasicInfoDialog: false,
      maxUploadImageFileSize: 0,
      studentImage: null,
      studentImageUrl: null,
      countriesAndcities: [],
      countryNowGuid: null,
      countryPermanentGuid: null,
      cityNowGuid: null,
      cityPermanentGuid: null,
      citiesNow: [],
      citiesPermanent: [],
      studentDob: null,
      name: "",
      per: "",
      loading: false,
      loadSave: false,
      showEditBtn: false,
      email: "",
      mobileNow: "",
      motherName: "",
      bloodType: ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      bloodTypeSelected: "O+",
      studentGender: [
        { GenderType: 1, DisplayText: this.$t("abs.Male") },
        { GenderType: 0, DisplayText: this.$t("abs.Female") }
      ],
      studentGenderSelected: null,
      teacherInfo: {},
      teacherLectures: [],
      studentPayments: [],
      studentPaymentsTotal: {}
    };
  },
  watch: {
    menuDate(val) {
      // eslint-disable-next-line no-unused-expressions
      val && setTimeout(() => (this.activePicker = "YEAR"));
    }
  },
  computed: {
    checkEditorsVaildation() {
      return !this.passwordOld || this.confirmPassword !== this.passwordNew;
    },
    countries() {
      return [
        ...new Set(
          this.countriesAndcities.map(({ country, countryGuid }) => ({
            country,
            countryGuid
          }))
        )
      ];
    }
  },
  methods: {
    getCities(type) {
      if (type == "now") {
        this.citiesNow = this.countriesAndcities.filter(i => i.countryGuid == this.countryNowGuid);
      } else if (type == "permanent") {
        this.citiesPermanent = this.countriesAndcities.filter(
          i => i.countryGuid == this.countryPermanentGuid
        );
      }
    },

    saveChangePassword() {
      if (this.passwordNew && this.passwordOld && this.confirmPassword) {
        this.axios
          .post("Account/ChangePassword", {
            OldPassword: this.passwordOld,
            NewPassword: this.passwordNew,
            ConfirmPassword: this.confirmPassword,
            LogOutFromAllDevices: this.logOutFromAllDevices
          })
          .then(res => {
            this.loadSave = false;
            this.dialog = false;
            if (res.data.statusCode == "1") {
              this.$swal.fire({
                title: this.$t("operationAccomplishedSuccessfully"),
                text: "",
                icon: "success",
                confirmButtonText: this.$t("close"),
                toast: true,
                position: "bottom-start",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              });
            } else if (res.data.statusCode == "2") {
              this.$swal.fire({
                title: this.$t("OldPasswordUnCorrect"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close")
              });
            } else if (res.data.statusCode == "3") {
              this.$swal.fire({
                title: this.$t("CannotChangePassword"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close")
              });
            } else {
              this.$swal.fire({
                title: this.$t("MakeSureToEnterACorrectPassword"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close")
              });
            }
            // if (this.logOutFromAllDevices)
            //  this.$router.push({ name: "Login" });
          })
          .catch(() => {
            this.loadSave = false;

            this.$swal.fire({
              title: this.$t("MakeSureToEnterACorrectPassword"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close")
            });
          });
      } else {
        this.loadSave = false;
        this.$swal.fire({
          title: this.$t("BeSureToEnterTheInformation"),
          text: "",
          icon: "error",
          confirmButtonText: this.$t("close")
        });
      }
    },

    getLectures() {
      this.overlay = true;
      this.axios
        .get("Subjects/Get")
        .then(res => {
          this.teacherLectures = res.data.data.subjects;
        })
        .catch(err => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    saveBasicInfo() {
      this.loadSave = true;

      const data = new FormData();
      if (this.studentImage != null) {
        data.append("PhotoFile", this.studentImage);
      }
      data.append("CountryNowGuid", this.countryNowGuid);
      data.append("CityNowGuid", this.cityNowGuid);
      data.append("CountryPermanentGuid", this.countryPermanentGuid);
      data.append("CityPermanentGuid", this.cityPermanentGuid);
      data.append("StudentBirthday", this.studentDob);

      data.append("email", this.email);
      data.append("mobileNow", this.mobileNow);
      data.append("motherName", this.motherName);
      data.append("bloodType", this.bloodTypeSelected);
      data.append("studentGender", this.studentGenderSelected);

      this.axios
        .post("Account/StudentBasicInfoSave", data)
        .then(res => {
          this.loadSave = false;
          this.studentBasicInfoDialog = false;

          if (res.data.statusCode == "1") {
            this.$swal.fire({
              title: this.$t("operationAccomplishedSuccessfully"),
              text: "",
              icon: "success",
              confirmButtonText: this.$t("close"),
              toast: true,
              position: "bottom-start",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: toast => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              }
            });
          } else {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close")
            });
          }
          this.getBasicInfo();
        })
        .catch(() => {
          this.loadSave = false;
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        });
    },
    getBasicInfo() {
      if (this.isInRole(2)) {
        this.overlay = true;
        this.axios
          .get("Account/GetTeacherBasicInfo")
          .then(res => {
            this.teacherInfo = res.data.data.teacherInfo;
            this.getLectures();
          })
          .catch(err => {})
          .finally(() => {
            this.overlay = false;
          });

        return;
      }

      this.axios
        .get("Account/GetStudentBasicInfo")
        .then(res => {
          this.studentBasicInfo = res.data.data.studentInfo;
          this.studentImageUrl = res.data.data.studentInfo.studentPhotoUrl;
          this.countriesAndcities = res.data.data.cities;
          this.maxUploadImageFileSize = res.data.data.maxUploadImageFileSize;
          this.countryNowGuid = res.data.data.studentInfo.countryNowGuid;
          this.countryPermanentGuid = res.data.data.studentInfo.countryPermanentGuid;
          this.cityNowGuid = res.data.data.studentInfo.cityNowGuid;
          this.cityPermanentGuid = res.data.data.studentInfo.cityPermanentGuid;
          this.studentDob = this.$moment(res.data.data.studentInfo.studentDateOfBirth).format(
            "YYYY-MM-DD"
          );
          this.showEditBtn = res.data.statusCode;
          this.email = res.data.data.studentInfo.email;
          this.mobileNow = res.data.data.studentInfo.mobileNow;
          this.motherName = res.data.data.studentInfo.motherName;
          this.bloodTypeSelected = res.data.data.studentInfo.bloodType;
          this.studentGenderSelected = res.data.data.studentInfo.studentGender;
          this.getCities("now");
          this.getCities("permanent");

          // console.log(res);
        })
        .catch(err => {})
        .finally(() => {
          this.overlay = false;
        });
    },
    getPayments() {
      if (this.isInRole(0)) {
        this.overlay = true;
        this.axios
          .get("Payments/Get")
          .then(res => {
            this.studentPayments = res.data.data.installments;
            this.studentPaymentsTotal = res.data.data.totalFormat;
          })
          .catch(err => {})
          .finally(() => {
            this.overlay = false;
          });
      }
    },
    imageChange(val) {
      if (this.studentImage != null && this.studentImage != "") {
        this.studentImageUrl = URL.createObjectURL(this.studentImage);
      }
    }
  },
  created() {
    this.getBasicInfo();
    this.getPayments();
  }
};
</script>

<style scoped>
.cardTransition >>> * {
  transition: all .5s ease-in-out;
}
</style>
